import { ReactNode } from 'react';
import { IMessage, IMessageAttachment } from '../model/IMessage';
import { ITicket } from '../model/ITicket';
import { INewMsgFormData, ticketApi } from '../api/ticket.api';
import { ITicketNewMsg } from '../components/tickets/TicketDetails';
import { z } from 'zod';
import { msg } from '../msg';
import checkFormStateByZod from '../utils/checkFormStateByZod';
import { useDispatch } from 'react-redux';
import { setTicket } from '../store/ticketSlice';
import toast from 'react-hot-toast';
import { getErrorFromCatch } from '../api/api';
import { useAccess } from './useAccess';
import { IEditedMessageState } from '../components/tickets/TicketMessage';

// --
// -- Determine the form schema
// --
const newMessage = z.object({
  message: z
    .string({ required_error: msg('empty-message') })
    .min(3, msg('empty-message')),
} as Record<keyof ITicketNewMsg, any>);

export function useTicketMessage() {
  const dispatch = useDispatch();
  const { getTicketMsgDeleteAccess, getTicketMsgEditAccess } = useAccess()
  const subjectShell = (subject?: string): ReactNode => <div className="inline-flex font-bold">{subject || 'unknown'}</div>;

  const calculateMsgAuthor = (message: IMessage, ticket: ITicket): ReactNode => 
    subjectShell(message.fromUser?.name || (`${ticket.client?.name || ticket.client?.email || ''} [Customer]`))

  const getTitleNode = (message: IMessage, ticket: ITicket): ReactNode => ({
    Public: calculateMsgAuthor(message, ticket),
    Note: subjectShell(`${message.fromUser?.name} [Internal Note]`),
    Private: <div>{subjectShell(message.fromUser?.name)} to {subjectShell(message.toUser?.name)}</div>,
    System: (
      <>
        <div className="">{subjectShell('System')}</div>
        {{
          'created': <div>{calculateMsgAuthor(message, ticket)} has created a {subjectShell(ticket.type.name)}</div>,
          'action': <div>{calculateMsgAuthor(message, ticket)} has used action {subjectShell(message.messageText || '')}</div>,
          'customerStatusAction': (
            <>
              <div>{calculateMsgAuthor(message, ticket)} has used action {subjectShell(message.messageText || '')}</div>
              <div>Order Management status has been updated</div>
            </>
          ),
        }[message.actionType || 'created']}
      </>),
  }[message.messageType]);

  const getAttachmentById = (message: IMessage, idAttachment: number): IMessageAttachment | undefined => 
    (message?.attachments || []).find(({ id }) => id === idAttachment);

  const deleteMsgAttachmentFromTicket = (ticket: ITicket, idMessage: number, idAttachment: number): ITicket => {
    const msgIndx = (ticket?.messages || []).findIndex(({ id }) => id === idMessage);
    if (msgIndx < 0) return ticket;

    const tmpAttachments = ticket.messages[msgIndx].attachments.filter(({ id }) => id !== idAttachment);
    const tmpMessages = [ ...ticket.messages ];
    tmpMessages[msgIndx] = {
      ...ticket.messages[msgIndx],
      attachments: tmpAttachments,
    }

    return {
      ...ticket,
      messages: tmpMessages,
    };
  };

  const getTicketWithUpdatedMessage = (ticket: ITicket, message: IMessage): ITicket => {
    const msgIndx = (ticket?.messages || []).findIndex(({ id }) => id === message.id);
    if (msgIndx < 0) return ticket;

    const tmpMessages = [ ...ticket.messages ];
    tmpMessages[msgIndx] = message;

    return {
      ...ticket,
      messages: tmpMessages,
    };
  };

  // --
  // -- API methods
  // --
  const [addMessageApi] = ticketApi.useAddMessageMutation();
  const [deleteMessageApi] = ticketApi.useDeleteMessageMutation();
  const [deleteMessageAttachmentApi] = ticketApi.useDeleteMessageAttachmentMutation();
  const [editMessageApi] = ticketApi.useEditMessageMutation();
  

  const addMessage = async (idTicket: number, data: ITicketNewMsg): Promise<Partial<Record<keyof ITicketNewMsg, string>> | null> => {
    // 1. Check data
    const errs = checkFormStateByZod(newMessage, data);
    if (errs && Object.values(errs)?.length) return errs;  

    // 2. Check if recipient doesn't exist in private message
    if (data.type === 'Private' && !data?.toUserId)
      return { toUserId: msg('empty-message-recipient')};
    
    // -- Prepared data for formData
    const { attachments, type, toUserId, ...request } = data;
    const baseData = {
      ...request,
      ...(data.type === 'Private' ? { toUserId } : {}),
      messageType: type,
    } as Omit<INewMsgFormData, 'attachments' | 'fromUserId'>;   

    // -- Pack data into formData
    const formData = new FormData();    
    formData.append(
      "request",
      new Blob([JSON.stringify(baseData)],
      { type: "application/json" },
    ));
    
    data.attachments?.length
      ? data.attachments.forEach((file) => formData.append('attachments[]', file))
      : formData.append('attachments[]', new Blob(['']));       

    try {
      const ticketFromAPI = await addMessageApi({
        idTicket, 
        body: formData,
      }).unwrap();
      dispatch(setTicket(ticketFromAPI)); // - Update loaded ticket to the global store
      return null;
    } catch (err) {
      toast.error(`${msg('failed-to-get-tickets')} ${getErrorFromCatch(err)}`);
      return { message: getErrorFromCatch(err) };
    }
  }

  const editMessage = async (ticket: ITicket, idMessage: number, data: IEditedMessageState): Promise<Partial<Record<keyof ITicketNewMsg, string>> | null> => {
    // 1. Check data
    const errs = checkFormStateByZod(newMessage, data);
    if (errs && Object.values(errs)?.length) return errs;  

    // -- Pack data into formData
    const { attachments, ...request } = data;
    const formData = new FormData();    
    formData.append("request", new Blob([JSON.stringify(request)], { type: "application/json" }));
    
    data.attachments?.length
      ? data.attachments.forEach((file) => formData.append('attachments[]', file))
      : formData.append('attachments[]', new Blob(['']));       

    try {
      const messageFromAPI = await editMessageApi({
        idMessage, 
        body: formData,
      }).unwrap();
      dispatch(setTicket(getTicketWithUpdatedMessage(ticket, messageFromAPI))); // - Update loaded ticket to the global store
      return null;
    } catch (err) {
      toast.error(`${msg('failed-to-get-tickets')} ${getErrorFromCatch(err)}`);
      return { message: getErrorFromCatch(err) };
    }
  }

  // --
  // -- null -> ok, string -> error
  const deleteMessage = async (message: IMessage): Promise<string | null> => {
    // 1. Check rights
    if (!getTicketMsgDeleteAccess(message)) {
      toast.error(msg('not-permissions-to-delete-msg'));
      return msg('not-permissions-to-delete-msg');  
    }
    
    try {
      const ticketFromAPI = await deleteMessageApi(message.id).unwrap();
      dispatch(setTicket(ticketFromAPI)); // - Update loaded ticket to the global store
      return null;
    } catch (err) {
      toast.error(`${msg('failed-to-delete-ticket-message')} ${getErrorFromCatch(err)}`);
      return `${msg('failed-to-delete-ticket-message')} ${getErrorFromCatch(err)}`;
    }
  }

  // --
  // -- null -> ok, string -> error
  const deleteMessageAttachment = async (message: IMessage, idAttachment: number, ticket: ITicket): Promise<string | null> => {
    // 1. Check rights
    if (!getTicketMsgEditAccess(message)) {
      toast.error(msg('not-permissions-to-edit-msg'));
      return msg('not-permissions-to-edit-msg');  
    }
    
    try {
      await deleteMessageAttachmentApi({ idMessage: message.id, idAttachment }).unwrap();
      dispatch(setTicket(deleteMsgAttachmentFromTicket(ticket, message.id, idAttachment))); // - Update loaded ticket to the global store
      return null;
    } catch (err) {
      toast.error(`${msg('failed-to-edit-ticket-message')} ${getErrorFromCatch(err)}`);
      return `${msg('failed-to-edit-ticket-message')} ${getErrorFromCatch(err)}`;
    }
  }

  return {
    getTitleNode,
    addMessage,
    deleteMessage,
    deleteMessageAttachment,
    getAttachmentById,
    editMessage,
  };
}
