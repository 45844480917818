import { IPageParams } from "../api/IApi";
import { TOrderSearchState } from "../components/orders/OrderList";
import { IUrlParamsMap } from "../routing/IUrlParamsMap";
import useSchemaSearchParams from "./useSerarchParams";

export const initOrderSearchState: TOrderSearchState = {
  orderId: '',
  siEmail: '',
  invoice: '',  
};
export const initOrderPageParam: IPageParams = {
  page: 0,
  size: 30,
  q: '',
  sort: 'timeCreate',
  sortDir: 'desc',
};

export default function useOrder() {
  const param = useSchemaSearchParams();

  const getOrderSearchStateFromUrl = (): TOrderSearchState => ({
    ...initOrderSearchState,
    ...(param.get('orderSearchId') ? { orderId: param.get('orderSearchId') } : {}),
    ...(param.get('orderSearchSiEmail') ? { siEmail: param.get('orderSearchSiEmail') } : {}),
    ...(param.get('orderSearchInvoice') ? { invoice: param.get('orderSearchInvoice') } : {}),
  });

  const getOrderSearchPageParamFromUrl = (): IPageParams => ({
    ...initOrderPageParam,
    ...(Number(param.get('orderSearchPage'))
      ? { page: Number(param.get('orderSearchPage')) }
      : {}),
    ...(param.get('orderSearchSort')
      ? { sort: param.get('orderSearchSort') }
      : {}),
    ...(param.get('orderSearchSortDir')
      ? { sortDir: param.get('orderSearchSortDir') === 'desc' ? 'desc' : 'asc' }
      : {}),
  });

  const getPageParamsAppliedUrlString = (pageParams: IPageParams): string => {
    const urlParams: IUrlParamsMap = {
      orderSearchId: param.get('orderSearchId') || initOrderSearchState.orderId,
      orderSearchSiEmail: param.get('orderSearchSiEmail') || initOrderSearchState.siEmail,
      orderSearchInvoice: param.get('orderSearchInvoice') || initOrderSearchState.invoice,
      orderSearchPage: Number(pageParams?.page)
        ? String(pageParams?.page)
        : /* param.get('orderSearchPage') || */ String(initOrderPageParam.page), 
      orderSearchSort: pageParams?.sort /* || param.get('orderSearchSort') */ || initOrderPageParam.sort,
      orderSearchSortDir: pageParams?.sortDir /* || param.get('orderSearchSortDir') */ || initOrderPageParam.sortDir,
    };
    
    return new URLSearchParams(urlParams).toString();
  }

  const getStateAppliedUrlString = (orderSearchState: TOrderSearchState): string => new URLSearchParams({
    orderSearchId: orderSearchState?.orderId,
    orderSearchSiEmail: orderSearchState?.siEmail,
    orderSearchInvoice: orderSearchState?.invoice,
  }).toString();

  return {
    getOrderSearchStateFromUrl, // search state
    getStateAppliedUrlString,
    getOrderSearchPageParamFromUrl,
    getPageParamsAppliedUrlString,
  };
}