import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { orderApi } from '../api/order.api';
import Loading from '../components/common/loading/Loading';
import TitlePage from '../components/common/title/TitlePage';
import OrderDetails from '../components/orders/OrderDetails';
import Page2Line from '../layouts/dashboard/Page2Line';
import Meta from '../layouts/Meta';

const OrderPage: FC = () => {
  const { orderId } = useParams();
  const { data: order, isFetching } = orderApi.useGetOrderQuery(orderId || '', { skip: !orderId }); 

  return (
    order && (
      <>
        <Meta title={`Order #${order.orderId} Details`} />
        <Page2Line
          noPadding
          header={(
            <TitlePage>Order #{order.orderId} Details</TitlePage>
          )}
        >
          <OrderDetails order={order} />
        </Page2Line>      
      </>
    )
    || (isFetching && <Loading />)
    || <Navigate to="/" />
  );
}

export default OrderPage;
