import type { PropsWithChildren, MouseEvent, CSSProperties, MutableRefObject, RefObject } from "react";
import { TFormSize } from "./TFormSize";

export default function Button ({  
  children,
  onClick,
  size = 'medium',
  variant = 'filled',
  type = 'button',
  disabled = false,
  className,
  style,
  isNotFullWidth = false,  
  btnRef,
}: PropsWithChildren<{
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => void,
  size?: TFormSize,
  variant?: 'outline' | 'filled',
  type?: 'button' | 'reset' | 'submit',
  disabled?: boolean,
  className?: string,
  style?: CSSProperties,
  isNotFullWidth?: boolean,  
  btnRef?: MutableRefObject<HTMLInputElement | null | undefined>,
}>) {
  return (
    <button
      type={type}
      {...(onClick ? { onClick } : {})}
      {...(style ? { style } : {})}
      {...(btnRef ? { ref: btnRef as RefObject<HTMLButtonElement> }: {})}
      disabled={disabled}
      className={`
        border-[1px] font-bold transition duration-300 py-2 px-4 rounded-lg disabled:opacity-50 tracking-wide
        ${!!!isNotFullWidth && 'w-full'} 
        ${{
          'tiny':   'text-xs py-[3px] px-[7px]',
          'small':  'text-xs py-1 px-2',
          'medium': '',
          'large':  'text-sm py-3 px-4',
        }?.[size]} 
        ${{
          'submit' : {
            'outline': 'bg-white shadow-sm border-border_color hover:text-text_hard text-text hover:shadow-md',
            'filled': 'bg-primary border-primary_dark hover:bg-primary_hover hover:border-primary text-white shadow-sm hover:shadow-lg',
          },
          'reset' : {
            'outline': 'bg-white shadow-sm border-border_color hover:text-text_hard text-text hover:shadow-md',
            'filled': 'bg-primary border-primary_dark hover:bg-primary_hover hover:border-primary text-white shadow-sm hover:shadow-lg',
          },
          'button' : {
            'outline': 'bg-white shadow-sm border-border_color hover:text-text_hard text-text hover:shadow-md',
            'filled': 'bg-white hover:bg-gray-300 hover:border-gray-300 text-black shadow-sm hover:shadow-lg',
          },
        }?.[type]?.[variant]}
        ${className ? className : ''}
      `}>
      {children}
    </button>
  );
}