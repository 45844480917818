import { FC, useEffect, useState } from "react";
import { ITicket } from "../../model/ITicket";
import Modal from "../common/Modal";
import Button from "../form/Button";
import Input from "../form/Input";
import { useTicket } from "../../hooks/useTicket";
import toast from "react-hot-toast";
import { msg } from "../../msg";
import TextButtom from "../common/buttom/TextButtom";
import parseISOToString from "../../utils/parseISOToString";

const TicketInternalNote: FC<{ ticket: ITicket }> = ({ ticket }) => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const [internalNote, setInternalNote] = useState<string>();
  const { editTicketParams } = useTicket();

  const handleEditInternalNote = (internalNote: string) => async () => {
    try {
      await editTicketParams({
        idTicket: ticket.id,
        internalNote,
      });
      toast.success(msg('success-ticket-editing'));
      setIsShown(() => false);
    } catch (err) {
    }
  };

  useEffect(() => {
    setInternalNote(() => ticket?.internalNote || undefined);
  }, [ticket?.internalNote]);

  return (
    <div>
      {!ticket?.internalNote
        ? (
          <TextButtom onClick={() => setIsShown(() => true)}>
            Add Ticket Note
          </TextButtom>          
        ) : (
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              {!!ticket?.noteChanged  && (
                <div className="">
                  {parseISOToString(ticket.noteChanged)} :
                </div>
              )}              
              {ticket.internalNote}
            </div>            
            <div className="flex gap-2">
              <TextButtom onClick={() => setIsShown(() => true)}>
                Edit Ticket Note
              </TextButtom>              
              or
              <TextButtom type="delete" onClick={handleEditInternalNote('')}>
                Delete
              </TextButtom>              
            </div>
          </div>
        )
      }      

      <Modal
        isShown={isShown}
        setIsShown={setIsShown}
        title={`Ticket Note for Ticket #${ticket.id}`}
        style={{ maxWidth: '550px' }}
      > 
        <div className="flex flex-col gap-4">
          <Input
            name="internalNote"
            value={internalNote || ''}
            type="textarea"
            handle={(e) => setInternalNote(() => e.target.value)}
          />
          <div className="flex justify-end">
            <Button
              isNotFullWidth
              onClick={handleEditInternalNote(internalNote || '')}
            >
              {ticket?.internalNote
                ? 'Update Ticket Note'
                : 'Add Ticket Note'
              }
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TicketInternalNote;
