import { FC, PropsWithChildren, ReactNode } from "react";

const Tpl3Cols : FC<PropsWithChildren<{
  leftSidebar?: ReactNode,
  rightSidebar?: ReactNode,
}>> = ({
  leftSidebar,
  rightSidebar,
  children,
}) => (
  <div className="w-full flex h-full">
    {!!leftSidebar && (
      <div className="basis-[265px] p-4">
        {leftSidebar}
      </div>
    )}
    <div className="flex-1 flex flex-col border-border_color border-l-[1px] border-r-[1px] h-full">
      {children}
    </div>
    {!!rightSidebar && (
      <div className="basis-[265px] h-full overflow-y-auto stylledScroll">
        {rightSidebar}
      </div>
    )}
</div>
);

export default Tpl3Cols;
