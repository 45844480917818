import { FC, useState } from "react";
import { icons } from "../../icons/IconSet";
import Modal from "../common/Modal";
import FileList from "../files/FileList";
import FilesUploader from "../files/FileUploader";
import Button from "../form/Button";
import Input, { IInput } from "../form/Input";

const TicketMsg: FC<IInput & {
  buttonTitle?: string,
  handleClick: () => unknown,
  filesState?: File[],
  setFileState?: (files: File[]) => unknown,
  disabled?: boolean,
}> = ({
  buttonTitle,
  handleClick,
  filesState,
  setFileState,
  disabled = false,
  ...props
}) => {
  const [fileWinShown, setFileWinShown] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const handleChange = (fileList: FileList) => setFiles((prevState) => [
    ...(prevState || []),
    ...Array.from(fileList),
  ]);

  const handleAttach = () => {
    setFileState && setFileState([
      ...(filesState || []),
      ...(files || []),
    ]);
    setFileWinShown(() => false);
    setFiles(() => []);
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="">
        <Input
          type="redactor"          
          placeholder="Enter your message here..."
          classNameField="
            ring-white shadow-none hover:ring-white text-md 
            focus:outline-none focus:ring-0 focus:ring-white
          "
          disabled={disabled}
          {...props}
        />
      </div>
      <div className="flex justify-between">
        {!!filesState && (
          <div className="flex gap-2">
            <div className="">
              <Button
                variant="outline"
                size="small"
                onClick={() => !disabled && setFileWinShown(() => true)}
              >
                {icons('attach', { width: '16px' })}
              </Button>
            </div>
            
            <div className="mt-1">
              <FileList 
                disabled={disabled}
                files={filesState}
                setFiles={setFileState}
              />
            </div>

            <Modal
              isShown={fileWinShown}
              setIsShown={setFileWinShown}
              title={`Attach files to message`}
              style={{ maxWidth: '550px' }}
            > 
              <div className="flex flex-col gap-4">
                <FilesUploader handleChange={handleChange} />                
                {!!files && (
                  <div className="mt-4">
                    <FileList files={files} setFiles={setFiles} />
                  </div>
                )}
                <div className="flex justify-end">
                  <Button
                    isNotFullWidth
                    onClick={handleAttach}
                  >
                    Attach Files
                  </Button>
                </div>                
              </div>
            </Modal>
          </div>
        )}
        <div className="ml-2">
          <Button
            isNotFullWidth
            size="small"
            onClick={handleClick}
            className="whitespace-nowrap"
          >
            {buttonTitle || 'Submit'}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TicketMsg;
