import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useUser } from '../../hooks/useUser';
import { IUser, IUserRequest } from '../../model/IUser';
import { msg } from '../../msg';
import { getVendors } from '../../store/commonSlice';
import { IZodErrorsState } from '../../utils/checkFormStateByZod';
import Button from '../form/Button';
import Checkbox from '../form/Checkbox';
import FormTitle from '../form/FormTitle';
import Input, { TInputEvent } from '../form/Input';
import UserRoleMultiSearchField from '../user/UserRoleMultiSearchField';
import VendorRoleMultiSearchField from '../vendor/VendorRoleMultiSearchField';
import VendorSelect from '../vendor/VendorSelect';
import { useAccess } from '../../hooks/useAccess';
import AgentRoleMultiSearchField from '../agent/AgentRoleMultiSearchField';

const UserForm: FC = () => {
  const { addUser } = useUser();
  const navigate = useNavigate();
  const vendorList = useTypedSelector(getVendors());

  // -- Check permissions
  const { getSettingAccess } = useAccess();
  useEffect(() => {
    !getSettingAccess('add-user') && navigate('/users');
  }, []);

  // -- Init form state
  const initState: IUserRequest = {
    email: '',
    name: '',
    phone: '',
    isActive: true,
    roles: 'ROLE_USER',
    vendor: '',
    vendorRole: '',
    agentRole: '',
    password: '',
    passwordConfirm: '',
    sendInviteEMail: false,
  };

  // -- States
  const [userState, setFormState] = useState<IUserRequest>(initState);
  const [userErrState, setNewUserErrState] = useState<Partial<IZodErrorsState<IUserRequest>>>();
  const [isPending, setIsPending] = useState<boolean>(() => false);

  // -- Fill default vendor
  useEffect(() => {
    vendorList?.length && !userState?.vendor && setFormState((prevState) => ({
      ...prevState,
      vendor: String(vendorList?.[0]?.id) || '',
    }));
  }, [vendorList]);

  const handle = (key: Partial<keyof typeof initState>) => (e: TInputEvent) => 
    setFormState((prevState) => ({
      ...prevState,
      [key]: e ?.target?.value || '',
    }));
  
  const handleCheckbox = (key: Partial<keyof typeof initState>) => () => 
    setFormState((prevState) => ({
      ...prevState,
      [key]: !!!prevState?.[key],
    }));

  const hendleAddUser = async () => {
    setIsPending(() => true);
    const errs = await addUser(userState);
      
    if (errs) {
      setNewUserErrState(() => errs);
      setIsPending(() => false);
      return;
    }
      toast.success(msg('user-create'));      
      return navigate('/users'); 
  };

  return (
    <div className="w-full grid grid-cols-12">
      <div className="p-4 col-span-12 lg:col-span-6 border-border_color border-b-[1px] pb-6">
          <FormTitle>Main User Info</FormTitle>
          <div className='grid grid-cols-12 gap-x-10 gap-y-6 items-center'>            
            <Input
              label="Email (required)"
              name="email"
              placeholder='Manager email'
              value={userState.email}
              handle={handle('email')}
              className='col-span-6 md:col-span-6'
              error={userErrState?.email}
              disabled={isPending}
            />
            <Checkbox
              label="Enable"
              name="active"
              value={userState.isActive}
              handleToggle={handleCheckbox('isActive')}
              className='col-span-3'
              disabled={isPending}
            />
            <Checkbox
              label="Send invite"
              name="active"
              value={userState.sendInviteEMail}
              handleToggle={handleCheckbox('sendInviteEMail')}
              className='col-span-3'
              disabled={isPending}
            />         
            <Input
              label="Name"
              name="name"
              placeholder='Manager name'
              value={userState.name}
              handle={handle('name')}
              className='col-span-6'
              error={userErrState?.name}
              disabled={isPending}
            />
            <Input
              label="Phone"
              name="phone"
              placeholder='Manager phone'
              value={userState.phone}
              handle={handle('phone')}
              className='col-span-6'
              error={userErrState?.phone}
              disabled={isPending}
            />               

            <Input
              label='Password (required)'
              name="password"
              value={userState.password}
              handle={handle('password')}
              type="password"
              className='col-span-6'
              error={userErrState?.password}
              disabled={isPending}
            />
            <Input
              label='Confirm Password (required)'
              name="passwordConformed"
              value={userState.passwordConfirm}
              handle={handle('passwordConfirm')}
              type="password"
              className='col-span-6'
              error={userErrState?.passwordConfirm}
              disabled={isPending}
            />            
          </div>
      </div>
      <div className="p-4 col-span-12 lg:col-span-6 border-border_color border-l-[1px] border-b-[1px] pb-6 bg-bg_light">
        <FormTitle>Role Info</FormTitle>
        <div className="grid grid-cols-12 gap-x-10 gap-y-10">
          <UserRoleMultiSearchField
            label="Roles"
            name="roles"
            placeholder='User role'
            value={userState.roles}
            handle={handle('roles')}
            className='col-span-12'
            error={userErrState?.roles}
            disabled={isPending}
          />

          {userState.roles.includes('ROLE_VENDOR') && (
            <div className='col-span-12 flex flex-col gap-4'>
              <VendorSelect
                label="Vendor"
                name="vendor"
                placeholder='Attach to Vendor'
                value={userState.vendor}
                handle={handle('vendor')}                
                error={userErrState?.vendor}
                disabled={isPending}
              />
              {!!userState.vendor && (
                <VendorRoleMultiSearchField
                  label="Vendor Role"
                  name="vendorRole"
                  placeholder='Role for Vendor'
                  value={userState.vendorRole}
                  handle={handle('vendorRole')}
                  className='col-span-12'
                  error={userErrState?.vendorRole}
                  disabled={isPending}
                />
              )}                
            </div>
          )}
          
          {userState.roles.includes('ROLE_AGENT') && (
            <div className='col-span-12'>
              <AgentRoleMultiSearchField
                label="Agent Role"
                name="agentRole"
                placeholder='Role for Agent'
                value={userState.agentRole}
                handle={handle('agentRole')}
                error={userErrState?.agentRole}
                disabled={isPending}
              />
            </div>
          )}

        </div>      
      </div>
      <div className="flex justify-end gap-4 mt-6 col-span-12 p-4 ">
          <Button
            onClick={() => setFormState(() => initState)}
            variant="outline"
            size='small'
            isNotFullWidth
            disabled={isPending}
          >
            Reset From
          </Button>
          <Button
            onClick={() => hendleAddUser()}
            size='small'
            isNotFullWidth
            disabled={isPending}
          >
            Add User
          </Button>            
      </div>
    </div>
  );
}

export default UserForm;
