import { FC, useState } from 'react';
import Button from '../form/Button';
import Modal from './Modal';
import Truncate from './Truncate';

const TextMore: FC<{
  text: string,
  title?: string,
}> = ({ 
  text,
  title,
}) => {
  const [isModal, setIsModal] = useState<boolean>(false);

  return (
    <div className="flex gap-2 items-center">
      <Truncate>
        {text}
      </Truncate>

      <Button
        isNotFullWidth
        size="small"
        onClick={() => setIsModal(() => true)}
        className="whitespace-nowrap"
      >
        More
      </Button>

      {!!isModal && (
        <Modal
          title={title}
          style={{ maxWidth: '600px' }}
          onClose={() => setIsModal(() => false)}
        >
          {text}
        </Modal>
      )}
    </div>
  );
}

export default TextMore;
