import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { IOrder } from '../model/IOrder';

export interface TOrderSearchState {
  orderId: IOrder['orderId'],
  siEmail: IOrder['siEmail'],
  invoice: IOrder['invoice'],
};
export interface IOrderSliceInitState {
  searchState: TOrderSearchState,
}
export const initOrderSearchState: TOrderSearchState = {
  orderId: '',
  siEmail: '',
  invoice: '',  
};

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState: {
    searchState: initOrderSearchState,
  } as IOrderSliceInitState,

  reducers: {
    storeOrderSearchState: (state: IOrderSliceInitState, action: PayloadAction<TOrderSearchState>) => {
      state.searchState = action.payload;
    },
  },
});

// Reducers
export default orderSlice.reducer;
export const {
  storeOrderSearchState,
} = orderSlice.actions;

// Selectors
export const getOrderSearchState = () => (state: RootState) => state.orderSlice.searchState;
