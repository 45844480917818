import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { IPageParams } from '../api/IApi';
import { ITicket } from '../model/ITicket';
import { ITicketTab } from '../model/ITicketTab';
import { ITicketNewMsgErrMap, ITicketNewMsgMap } from '../components/tickets/TicketDetails';

export interface IticketInitState {
  ticketTabs?: ITicketTab[],
  activeTabIndx?: number,
  activeTabId?: string,
  tickets?: Record<string, ITicket>,
  pageParams: IPageParams,
  msgState?: ITicketNewMsgMap,
  msgStateErr?: ITicketNewMsgErrMap,
}

const ticketSlice = createSlice({
  name: 'ticketSlice',
  initialState: {
    ticketTabs: undefined,
    activeTabIndx: undefined,
    activeTabId: undefined,
    tickets: undefined,
    pageParams: {
      page: 0,
      size: 50,
      q: '',
      sort: '',
      sortDir: 'asc',
    },
    filterParams: {
      createdFrom: '',
      createdTill: '',
    },
    msgState: {
      '': {
        toUserId: '',
        message: '',
        attachments: [],
      }
    },
    msgStateErr: undefined,
  } as IticketInitState,

  reducers: {
    setTicketTabs: (state: IticketInitState, action: PayloadAction<ITicketTab[]>) => {
      state.ticketTabs = action.payload;
    },
    setTickets: (state: IticketInitState, action: PayloadAction<Record<string, ITicket>>) => {
      state.tickets = action.payload;
    },
    setTicket: (state: IticketInitState, action: PayloadAction<ITicket>) => {      
        state.tickets = {
          ...state.tickets,
          [action.payload.id]: action.payload,
        };
    },
    setTicketsPageParams: (state: IticketInitState, action: PayloadAction<IPageParams>) => {
      state.pageParams = action.payload;
    },
    addTicketTab: (state: IticketInitState, action: PayloadAction<ITicketTab>) => {
      state.activeTabId = action.payload.id;

      if (!(state.ticketTabs || []).find(({ id }) => id === action.payload.id))
        state.ticketTabs = [...(state.ticketTabs || []), action.payload];
    },
    addNewTicketTab: (state: IticketInitState, action: PayloadAction<string>) => {
      state.activeTabId = action.payload;
      state.ticketTabs = [...(state.ticketTabs || []), { id: action.payload }];
    },
    removeTicketTabById: (state: IticketInitState, action: PayloadAction<string>) => {
      // -- Find index for a tab with the id
      const indx = (state.ticketTabs || []).findIndex(({ id }) => id === action.payload);     
      state.ticketTabs = (state.ticketTabs || []).filter((_, i) => i !== indx );      

      if (state?.activeTabId === undefined || !state.ticketTabs?.length)
        state.activeTabId = undefined;
      else if (state.activeTabId === action.payload)
        state.activeTabId = state.ticketTabs?.[0]?.id 
          ? String(state.ticketTabs?.[0].id)
          : undefined;      
    },
    setActiveTabIndx: (state: IticketInitState, action: PayloadAction<number>) => {
      state.activeTabIndx = action.payload;
    },
    setActiveTabId: (state: IticketInitState, action: PayloadAction<string>) => {
      state.activeTabId = action.payload;
    },
    removeActiveTabIndx: (state: IticketInitState) => {
      state.activeTabIndx = undefined;
    },
    removeActiveTabId: (state: IticketInitState) => {
      state.activeTabId = undefined;
    },
    removeAllTicketTabs: (state: IticketInitState) => {
      state.activeTabId = undefined;
      state.ticketTabs = undefined;
    },
    setMsgStateMap: (state: IticketInitState, action: PayloadAction<ITicketNewMsgMap>) => {
      state.msgState = action.payload;
    },
    setMsgStateErrMap: (state: IticketInitState, action: PayloadAction<ITicketNewMsgErrMap>) => {
      state.msgStateErr = action.payload;
    },
    // removeMsgStateMapItem: (state: IticketInitState, action: PayloadAction<string> /* idTicket */) => {
    //   if (!state.msgState) return;
    //   state.msgState = Object.fromEntries(Object.entries(state.msgState)
    //     .filter(([idTicket, msgState]) => idTicket !== action.payload));

    //   if (!state.msgStateErr) return;
    //   state.msgStateErr = Object.fromEntries(Object.entries(state.msgStateErr)
    //     .filter(([idTicket, msgState]) => idTicket !== action.payload));
    // },
  },
});

// Reducers
export default ticketSlice.reducer;
export const {
  setTickets,
  setTicket,
  addTicketTab,
  addNewTicketTab,
  setActiveTabIndx,
  setTicketTabs,
  removeActiveTabId,
  setActiveTabId,
  removeTicketTabById,
  setTicketsPageParams,
  removeAllTicketTabs,
  setMsgStateMap,
  setMsgStateErrMap,
  // removeMsgStateMapItem,
} = ticketSlice.actions;

// -- 
// -- Selectors
// -- 
export const getTicketStore = () => (state: RootState) => state.ticketSlice;

export const getTickets = () => (state: RootState) => state.ticketSlice.tickets;

export const getTicketById = (id: string | number) => (state: RootState) => state.ticketSlice.tickets?.[id];

export const getTicketTabs = () => (state: RootState) => state.ticketSlice.ticketTabs;

export const getActiveTicketTabIndx = () => (state: RootState) => state.ticketSlice.activeTabIndx;

export const getActiveTicketTabId = () => (state: RootState) => state.ticketSlice.activeTabId;

export const getTicketPageParams = () => (state: RootState) => state.ticketSlice.pageParams;

export const getMsgState = () => (state: RootState) => state.ticketSlice.msgState;

export const getMsgStateErr = () => (state: RootState) => state.ticketSlice.msgStateErr;

// export const getTicketFilterParams = () => (state: RootState) => state.ticketSlice.filterParams;

export const getActiveTicketTab = () => (state: RootState) => state.ticketSlice?.activeTabId !== undefined
  ? state.ticketSlice.ticketTabs?.find(({ id }) => id === state.ticketSlice.activeTabId)
  : undefined;
