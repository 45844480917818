import { FC, PropsWithChildren, ReactNode } from "react";
import Truncate from "../Truncate";

export type IInfoWidget = PropsWithChildren<{
  title: ReactNode,
  className?: string,
  isTruncate?: boolean,
}>;

const InfoWidget: FC<IInfoWidget> = ({
  title,
  children,
  className = '',
  isTruncate = false,
}) => (
<div className={`flex flex-col gap-[2px] justify-start ${className}`}>
  <div className="font-bold">{title}</div>
  <div className="">
    {isTruncate
      ? <Truncate>{children || '-'}</Truncate>
      : children || '-'
    }
  </div>
</div>
);

export default InfoWidget;
