
import { TRole } from "../model/IRole";

// --
export type TPageUrl =
| '/oauth/signIn'

| '/dashboard'

| '/customer-service/cs-tickets'

| '/sales/sales-reps'
| '/sales/add-edit-product'
| '/sales/coupon-discount-system'
| '/sales/special-offers'
| '/sales/make-an-offer-discount-rules'

| '/orders'
| '/orders/:orderId'

| '/users'
| '/users/new'

;

type IAccess<T extends string | number | symbol> = {
  [key in T]: (Partial<TRole | '*'>)[];
};

// --
// -- Access map
// --
export const pagesAccess: IAccess<TPageUrl> = {
  '/oauth/signIn':                        ['*'],

  '/dashboard':                           ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_AGENT', 'ROLE_VENDOR'],

  '/customer-service/cs-tickets' :        ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_VENDOR'],

  '/sales/sales-reps' :                   ['ROLE_ADMIN'],
  '/sales/add-edit-product' :             ['ROLE_ADMIN'],
  '/sales/coupon-discount-system' :       ['ROLE_ADMIN'],
  '/sales/special-offers' :               ['ROLE_ADMIN'],
  '/sales/make-an-offer-discount-rules' : ['ROLE_ADMIN'],
  
  '/orders' :      ['ROLE_ADMIN', 'ROLE_AGENT'],
  '/orders/:orderId' :      ['ROLE_ADMIN', 'ROLE_AGENT'],

  '/users'  :       ['ROLE_ADMIN'],
  '/users/new' :    ['ROLE_ADMIN'],
};

export const settingAccess = {
  'add-user':     ['ROLE_ADMIN'],
  'edit-user':    ['ROLE_ADMIN'],
  'delete-user':  ['ROLE_ADMIN'],

  'ticket-asigned-agent':   ['ROLE_ADMIN', 'ROLE_AGENT'],
  'ticket-asigned-vendor':  ['ROLE_ADMIN', 'ROLE_VENDOR'],
  'ticket-asigned-all':     ['ROLE_ADMIN'],
  
  'ticket-public-msg':            ['ROLE_ADMIN', 'ROLE_AGENT'],
  'ticket-internal-msg':          ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_VENDOR'],
  'ticket-internal-note':         ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_VENDOR'],
  'ticket-internal-scope-all':    ['ROLE_ADMIN'],
  'ticket-internal-scope-vendor': ['ROLE_AGENT'],
  'ticket-internal-scope-agent':  ['ROLE_VENDOR'],

  'order-priceFields':      ['ROLE_ADMIN', 'ROLE_AGENT'],
  'order-details':          ['ROLE_ADMIN', 'ROLE_AGENT'],
};

export type TSettingNames = keyof typeof settingAccess;
