import { FC, PropsWithChildren, useState } from "react";
import SideBarNav from "../../components/nav/SideBarNav";
import UserWidget from "../../components/user/UserWidget";
import SideBarButton from "./SideBarButton";
import { Outlet } from "react-router-dom";

const DashboardLayout: FC<PropsWithChildren> = ({ children }) => {
  const [isSideBar, setIsSideBar] = useState<boolean>(true);

  return (
    <div className="bg-[#eeeeee] h-full overflow-hidden">
      <div className={`
        fixed w-[255px] transition-all duration-300
        ${isSideBar
          ? 'left-0'
          : 'left-[-255px]'}
      `}>        
        <div className="flex grow flex-col gap-y-5 px-3 py-4">
          <div className="flex shrink-0 items-center">            
            <UserWidget /> 
          </div>
            <SideBarNav />
        </div>
      </div>

      <div className={`
        absolute flex top-[20px] z-[100] transition-all duration-300
        ${isSideBar
          ? 'left-[218px]'
          : 'left-[20px]'}
      `}>
        <SideBarButton
          isSideBar={isSideBar}
          setIsSideBar={setIsSideBar}
        />
      </div>

      <main className={`
        p-3 h-full transition-all duration-300
        ${isSideBar
          ? 'ml-[255px]'
          : 'ml-0'}
      `}>
        {children || <Outlet />}
      </main>
    </div>
  );
};

export default DashboardLayout;
