import { FC } from 'react';
import { useAccess } from '../../hooks/useAccess';
import InfoWidget, { IInfoWidget } from '../common/widgets/InfoWidget';
import priceFormatter from '../../utils/priceFormatter';
import { IOrder } from '../../model/IOrder';

const PriceWidget: FC<IInfoWidget & { 
  subtotalPrice?: IOrder['subtotalPrice'], 
  shipping?: IOrder['shipping'], 
  taxes?: IOrder['taxes'], 
  totalPrice?: IOrder['totalPrice'],
}> = ({ 
  subtotalPrice, 
  shipping, 
  taxes, 
  totalPrice,
  ...propsInfoWidget
}) => {
  const { getSettingAccess } = useAccess();
  
  return !getSettingAccess('order-priceFields')
    ? null
    : (
      <InfoWidget {...propsInfoWidget}>
        {Object.entries({
          'Part Price': priceFormatter(subtotalPrice),
          'Shipping': priceFormatter(shipping),
          'Taxes': priceFormatter(taxes),
          'Total': priceFormatter(totalPrice),
        }).map(([key, value]) => (
          <div key={key} className="flex">
            <div className="w-[80px]">{key}:</div>
            <div className="">{value}</div>
          </div>
        ))}
      </InfoWidget>
    )
}

export default PriceWidget;
