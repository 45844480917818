import { useRef } from "react";

export default function useClickByKey (keys: (React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>['key'])[] = ['Enter']) {
  const btnRef = useRef<HTMLInputElement | null>();

  const onKeyUp: React.KeyboardEventHandler<HTMLDivElement> = (eventKey) => {
    keys.includes(eventKey.key) && btnRef.current && btnRef.current.click();
  }

  return { btnRef, onKeyUp };
};