import { FC, useState } from "react";
import { useAccess } from "../../hooks/useAccess";
import { icons } from "../../icons/IconSet";
import RotateIcon from "../common/RotateIcon";
import { INavItem } from "./INav";
import SideBarNavLink from "./SideBarNavLink";
import { isCurrentPath } from "../../utils/isCurrentPath";
import { useLocation } from "react-router-dom";

const SideBarNav: FC = () => {
  const { getPageAccess } = useAccess();
  const { pathname } = useLocation();
  
  const filterNav = (nav: INavItem[]): INavItem[] =>
    nav.reduce((acc, item) => {
      if (item?.isNotAvailable || !item?.name) return acc;
      if (!item?.children?.length) return [...acc, item];
      
      const children = filterNav(item.children);
      if (children?.length) return [
        ...acc,
        {
          ...item,
          children 
        }
      ];
      return acc;
    }, [] as INavItem[]);

  const nav: INavItem[] = filterNav([
    // { name: 'Dashboard', to: '/dashboard', isNotAvailable: !getPageAccess('/dashboard') },
    { name: 'Customer Service', children: [
      { name: 'CS Tickets', to: '/customer-service/cs-tickets', isNotAvailable: !getPageAccess('/customer-service/cs-tickets') },
      { name: 'Orders', to: '/orders', isNotAvailable: !getPageAccess('/orders'), isCurrent: isCurrentPath(pathname, '/orders') },
    ]},
    { name: 'Admin Service', children: [
      { name: 'Users', to: '/users', isNotAvailable: !getPageAccess('/users') },
      { name: 'New User', to: '/users/new', isNotAvailable: !getPageAccess('/users/new') },
    ]},
    // { name: 'Sales', children: [
    //   // { name: 'Sales Reps', to: '/sales/sales-reps', isNotAvailable: !getPageAccess('/sales/sales-reps'), disabled: true },
    //   // { name: 'Add/Edit Product', to: '/sales/add-edit-product', isNotAvailable: !getPageAccess('/sales/add-edit-product'), disabled: true },
    //   // { name: 'Coupon/Discount System', to: '/sales/coupon-discount-system', isNotAvailable: !getPageAccess('/sales/coupon-discount-system'), disabled: true },
    //   // { name: 'Special Offers', to: '/sales/special-offers', isNotAvailable: !getPageAccess('/sales/special-offers'), disabled: true },
    //   // { name: 'Make An Offer Discount Rules', to: '/sales/make-an-offer-discount-rules', isNotAvailable: !getPageAccess('/sales/make-an-offer-discount-rules'), disabled: true },
    // ]}, 
    // { name: 'Orders', children: [
    //   // { name: 'Recent Orders', to: '/orders/recent-orders', isNotAvailable: !getPageAccess('/orders/recent-orders'), disabled: true },
    //   // { name: 'Orders By Create Date', to: '/orders/orders-by-create-dates', isNotAvailable: !getPageAccess('/orders/orders-by-create-dates'), disabled: true },
    //   // { name: 'Orders By Vehicle', to: '/orders/orders-by-vehicle', isNotAvailable: !getPageAccess('/orders/orders-by-vehicle'), disabled: true },
    //   // { name: 'Orders By Status', to: '/orders/orders-by-status', isNotAvailable: !getPageAccess('/orders/orders-by-status'), disabled: true },
    //   // { name: 'Orders By Website', to: '/orders/orders-by-website', isNotAvailable: !getPageAccess('/orders/orders-by-website'), disabled: true },
    //   // { name: 'Orders By Sales Reps', to: '/orders/orders-by-sales-reps', isNotAvailable: !getPageAccess('/orders/orders-by-sales-reps'), disabled: true },
    //   // { name: 'Freight Discount By Date', to: '/orders/freight-discount-by-date', isNotAvailable: !getPageAccess('/orders/freight-discount-by-date'), disabled: true },
    //   // { name: 'Calling Cart Abandoners', to: '/orders/calling-cart-abandoners', isNotAvailable: !getPageAccess('/orders/calling-cart-abandoners'), disabled: true },
    //   // { name: 'Order Lookup', to: '/orders/order-lookup', isNotAvailable: !getPageAccess('/orders/order-lookup'), disabled: true },
    //   // { name: 'Checkout Tracking', to: '/orders/checkout-tracking', isNotAvailable: !getPageAccess('/orders/checkout-tracking'), disabled: true },
    //   // { name: 'Quick Status', to: '/orders/quick-status', isNotAvailable: !getPageAccess('/orders/quick-status'), disabled: true },
    //   // { name: 'VIN & OEM Tracking', to: '/orders/vin-oem-tracking', isNotAvailable: !getPageAccess('/orders/vin-oem-tracking'), disabled: true },
    //   // { name: 'In-Stock Verification Stats', to: '/orders/in-stock-verification-stats', isNotAvailable: !getPageAccess('/orders/in-stock-verification-stats'), disabled: true },
    // ]},
  ]);

  const [openedNavsIndxs, setOpenedNavsIndxs] = useState<Record<string, boolean>>(nav.reduce((acc, { children }, i) => ({
    ...acc,
    [i]: !!children?.length
  }), {} as Record<string, boolean>)); 

  return (
    <nav className="flex flex-1 flex-col gap-4">
      {nav.map(({ name, children, to, onClick }, i) => children?.length
        ? (
          <div key={`${name}-${i}`} className=" overflow-hidden">
            <div
              className="text-text_pale px-2 flex justify-between cursor-pointer"
              tabIndex={-1}
              role="button"
              onClick={() => setOpenedNavsIndxs((prevState) => ({
                ...prevState,
                [i]: !prevState?.[i],
              }))}
            >
              {name}
              <RotateIcon isShown={openedNavsIndxs?.[i]}>
                {icons('chevron-down', { width: '16px' })}
              </RotateIcon>
            </div>
            <div className={`transition-all duration-300
              ${openedNavsIndxs?.[i]
                ? 'opacity-100 translate-y-1 h-auto'
                : 'opacity-0 -translate-y-0 h-0'}
            `}>
              {children.map((child, j) => !child?.to || child?.disabled
                ? <div
                    key={`${child?.name}-${j}`}
                    className="block py-[6px] px-4 opacity-50"
                  >
                    {child?.name}
                  </div>
                : (
                  <SideBarNavLink
                    key={`${child?.to}-${child?.name}`}
                    to={child?.to}
                    // isCurrent={isCurrentPath(pathname, child?.to) && !children.find(({ to }) => to === child?.to) && pathname !== child?.to}
                    isCurrent={child.isCurrent || pathname === child?.to}
                  >
                    {child?.name || '-'}
                  </SideBarNavLink>
              ))}
            </div>
          </div>            
        ) : (
          !to
            ? <div className="">{name}</div>
            : (
              <SideBarNavLink key={`${to}-${name}`} to={to}>
                {name || '-'}
              </SideBarNavLink>
            )
        )
      )}
    </nav>
  );
}

export default SideBarNav;
