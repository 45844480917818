import { FC } from 'react';
import { IOrderItem } from '../../model/IOrder';
import Table, { ITableTh } from '../common/table/Table';
import priceFormatter from '../../utils/priceFormatter';
import Truncate from '../common/Truncate';
import TextMore from '../common/TextMore';

const ProductsList: FC<{ products: IOrderItem[] }> = ({ products }) => {
  console.log('products = ', products);
  

  const thead: ITableTh[] = [
    { title: 'Name' },
    { title: 'Interchange' },
    { title: 'Part Type' },
    { title: 'Product #' },
    { title: 'Supplier #' },
    { title: 'Price' },
    { title: 'Make' },
    { title: 'Model' },
    { title: 'Year' },
    { title: 'Feed Price' },
    { title: 'Description' },
  ];

  return !products?.length
    ? null
    : (
      <Table
        thead={thead}
        tbody={products.map((product) => ({
          data: [
            product.name || '-',
            product.interchangeNumber || '-',
            product.partTypeCode || '-',
            product.pid || '-',
            product.supplierId || '-',
            product.price || '-',
            product.viMake || '-',
            product.viModel || '-',
            product.viYear || '-',
            priceFormatter(product.feedPrice),
            product.fullDescription
              ? <TextMore
                  title={`Description for ${product.name}`}
                  text={product.fullDescription}
                />
              : '-',
          ],
        }))}
      />
  );
}

export default ProductsList;
