const pipe = <T>(...fs: ((x: T) => T)[]) => (x: T) => fs.reduce((acc, f) => f(acc), x); 
const customReplace = (search: string, replace: string) => (text: string): string => text.replaceAll(search, replace);

const replaceMap: Record<string, string> = {
  '<pre': '<div',
  '</pre>': '</div>',
};

export function replaceTagsFromText(text: string): string {
  return pipe(...Object.entries(replaceMap).map(([search, replace]) => customReplace(search, replace)))(text);
}