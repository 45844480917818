import { ChangeEvent, KeyboardEventHandler, ReactNode, useState } from "react";
import Label from "./Label";
import { TFormSize } from "./TFormSize";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

export interface IInputOption {
  title?: ReactNode,
  value: string | number,
}

export type TInputEvent = ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
export type TInputEventHandle = (e: TInputEvent) => unknown;

export interface IInputTemplateHandler extends IInput {
  onSelect:(id:string)=>void
}

export interface IInput {
  id?: string,
  type?: 'email' | 'text' | 'password' | 'textarea' | 'redactor',
  name: string,
  label?: string,
  placeholder?: string,
  handle?: TInputEventHandle,
  defaultValue?: string,
  value?: string | number,
  size?: TFormSize,
  maxLength?: number,
  disabled?: boolean,
  error?: string,
  description?: string,
  role?: 'combobox',
  onFocus?: () => void,  
  onBlur?: () => void,
  onKeyUp?: KeyboardEventHandler<HTMLDivElement | HTMLTextAreaElement>,
  isError?: boolean,
  connectedUrlParam?: string, // If select value changes, this URL query param changes as well
  defaultOption?: IInputOption,
  options?: IInputOption[], // If options array exists it's a select input
  className?: string,
  classNameField?: string,
  setState?: (value: string) => unknown, // For redactor
}

export default function Input({
  label,
  placeholder,
  id,
  name,
  type = 'text',
  handle,
  defaultValue = '',
  size = 'medium',
  maxLength,
  value,
  disabled = false,
  error = '',
  description = '',
  role,
  onFocus = () => {},
  onKeyUp = () => {},
  defaultOption,
  options,
  isError,
  onBlur,
  className,
  classNameField = '',
  setState,
} : IInput) {
  // -- State for password input
  const [isPassShown, setIsPassShown] = useState<boolean>(false);
  
  // -- Common fields options for all field types
  const optionsList = {
    name,
    id: id || name,
    placeholder,
    ...(value !== undefined ? { value } : {} ),
    ...(value === undefined && defaultValue ? { defaultValue } : {} ),
    ...(role ? { role } : {} ),
    ...(maxLength ? { maxLength } : {} ),
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {      
      handle && handle(e);
      setState && setState(e?.target?.value);
    },
    disabled,
    onFocus,
    onBlur,
    onKeyUp,
    className: `
      first-letter:t-2 block w-full rounded-md py-1.5 pl-3 
      ring-1 ring-inset focus:ring-1 ring-gray-200 shadow-sm
      disabled:opacity-50
      ${error || isError
        ? 'text-pink-600 border-pink-500 border-[1px]'
        : 'text-gray-900 border-0 focus:ring-primary'}
      ${{
        'tiny':   'text-xs py-0 pl-1',
        'small':  'text-xs py-1 pl-2 sm:leading-6',
        'medium': 'text-md py-1 pl-2 sm:leading-6',
        'large':  'text-lg py-2.5 pl-4 sm:leading-6',
      }?.[size]}
      ${classNameField ?? ''}
      `,      
    };

  // --
  // -- State for showing the option list
  // --
  const [isOptionListShown, setIsOptionListShown] = useState<boolean>(false);
  const outOfOptionsClick = () => setIsOptionListShown(() => false);
  document.addEventListener("click", outOfOptionsClick);  
  // useEffect(() => document.removeEventListener("click", outOfOptionsClick), []);

  return (
    <div className={`
      flex flex-col
      ${{
        'tiny':   '',
        'small':  '',
        'medium': 'gap-1',
        'large':  'mt-2 gap-2',
      }?.[size]} 
      ${className || ''}      
    `}
    >
      {!!label && (
        <Label
          htmlFor={name}
          size={size}
        >
          {label}
        </Label>
      )}
        <div className={`
          flex items-center relative
          ${!['textarea', 'redactor'].includes(type) && {
            'tiny':   '',
            'small':  '',
            'medium': 'h-[36px]',
            'large':  'h-[46px]',
          }?.[size]} 
          ${className || ''}
        `}>
          {(options?.length && (
            <div className="relative w-full">
              <button
                type="button"
                className={`
                  relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm
                  ${{
                    'tiny':   '',
                    'small':  'sm:leading-6',
                    'medium': 'sm:leading-6',
                    'large':  'sm:leading-6',
                  }?.[size]}
                  ${classNameField}
                `}
                aria-haspopup="listbox" 
                aria-expanded="true"
                aria-labelledby="listbox-label"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOptionListShown((prevState) => !prevState);
                }}
              >
                <span className="flex items-center">
                  {options.find(({ value: val }) => String(val) === String(value))?.title
                    || defaultValue
                    || <>&nbsp;</>
                  }
                </span>                
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </span>
              </button>

              <ul
                className={`
                  absolute z-10 mt-1 max-h-56 w-full overflow-auto stylledScroll rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
                  ${isOptionListShown
                    ? 'block' 
                    : 'hidden'
                  }
                `}
                tabIndex={-1}
                role="listbox"
                aria-labelledby="listbox-label"
                aria-activedescendant="listbox-option-3"
              >
                {options.map(({ title, value: val }, i) => (
                  <li
                    key={`${val}-${i}`}
                    className="text-gray-900 relative select-none py-2 pl-3 pr-9 cursor-pointer hover:bg-bg_light"
                    id="listbox-option-0"
                    role="option"
                    onClick={() => {
                      handle && handle({ target: { value: String(val) }} as React.ChangeEvent<HTMLInputElement>);
                      setIsOptionListShown(() => false);
                    }}
                  >
                    <div className="flex items-center">
                      {title || value}
                    </div>
                    {val === value && (
                      <span className="text-primary absolute inset-y-0 right-0 flex items-center pr-4">
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                        </svg>
                      </span>
                    )}                      
                  </li>)
                )}
              </ul>
            </div>)           
          
          ) || (type === 'textarea' && <textarea {...optionsList} />
          ) || (type === 'password' && (
            <>
              <div
                className="absolute w-fit h-full top-0 right-0 pr-[10px] flex items-center justify-center cursor-pointer"
                onClick={() => setIsPassShown((prevState) => !prevState)}
              >
                {!!!isPassShown
                  ? '+' // <EyeIcon className="w-5 h-5 fill-slate-400" />
                  : '-' // <EyeSlashIcon className="w-5 h-5 fill-slate-700" />
                }
              </div>
              <input {...optionsList} type={isPassShown ? 'text' : 'password'} />
            </>)            
          ) || (type === 'redactor' && value !== undefined && setState && (
            <ReactQuill
              theme="snow"
              value={String(value)}
              onChange={setState}
              className="w-full h-full"
              readOnly={disabled}
            />)            
          ) || <input {...optionsList} type={type} />}
        </div>
        {!!error && <div className="text-pink-600 text-sm mt-1">{error}</div>}
        {!!description && <div className="text-slate-600 text-sm mt-1">{description}</div>}
    </div>
  );
}

