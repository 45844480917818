import { FC, PropsWithChildren } from 'react';

const TextButtom: FC<PropsWithChildren<{ 
  onClick?: () => unknown,
  type?: 'typical' | 'delete',
}>> = ({
  onClick,
  type = 'typical',
  children,
}) => (
  <div
    tabIndex={-1}
    role="button"
    onClick={() => onClick && onClick()}
    className={`
      cursor-pointer
      ${{
        typical: "text-primary hover:text-primary_hover",
        delete: "text-red-400 hover:text-red-500",
      }[type]}
    `}
  >
    {children}
  </div>
);
export default TextButtom;
