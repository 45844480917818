import { IOrder } from '../model/IOrder';
import api from './api';
import { IPageParams } from './IApi';
import { IApiPagable, IPagesResponse } from './IPagesResponse';

export interface ISearchOrder {
  orderId?: string,
  siEmail?: string,
  invoice?: string,
  createdFrom?: string,
  createdTill?: string,
}

export const orderApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchOrder: build.query<IOrder[], string>({
      query: (orderId) => ({
          url: '/ordermanagement/order',
          params: { orderId }
        }),
    }),

    getOrder: build.query<IOrder, string>({
      query: (orderId) => ({
          url: `/ordermanagement/order/${orderId}`,
        }),
    }),

    searchOrders: build.query<IPagesResponse<IOrder[]>, IPageParams & ISearchOrder>({
      query: ({ sort, sortDir, ...params }) => ({
          url: '/ordermanagement/search',
          params: {
            ...params,
            sort: sort ? `${sort},${sortDir}` : 'timeCreate,desc',
          },
        }),
    }),
  }),
});