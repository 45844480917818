import { FC } from 'react';
import TitlePage from '../components/common/title/TitlePage';
import OrderList from '../components/orders/OrderList';
import Page2Line from '../layouts/dashboard/Page2Line';
import Meta from '../layouts/Meta';

const OrdersPage: FC = () => (
  <>
    <Meta title={`Orders`} />
    <Page2Line
      noPadding
      header={(
        <TitlePage>Orders</TitlePage>
      )}
    >
      <OrderList />
    </Page2Line>
  </>
);

export default OrdersPage;
